import { createApp } from 'vue'
import App from './App.vue'
// 国际化
import i18n from './locales'
// 路由
import router from '@/router'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
// 状态管理
import { createPinia } from 'pinia'
// 国际化时间
import 'dayjs/locale/zh-cn'

const pinia = createPinia()
createApp(App).use(ElementPlus).use(i18n).use(router).use(pinia).mount('#app')