import {createI18n} from 'vue-i18n'
import zhCn from './zh-cn'
import en from './en'
import {getLocal} from "@/util/language";

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: getLocal() || 'en',
    messages: {zhCn, en}
})