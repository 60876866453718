import {createRouter, createWebHistory} from 'vue-router'

// 2. 定义一些路由
const routes = [{
    path: '/',
    name: 'home',
    component: () => import('@/components/home')
}, {
    path: '/category',
    name: 'category',
    component: () => import('@/components/goodsTypeList')
}, {
    path: '/product-detail',
    name: 'product-detail',
    component: () => import('@/components/goodDetail')
}, {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/components/aboutUs')
}]

// 3. 创建路由实例并传递 `routes` 配置
export default createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
    scrollBehavior: () => ({ top: 0 }),

})


