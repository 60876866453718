import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import CustomerHeader from '@/components/header';
import CustomerFooter from '@/components/footer';
import { useAppStore } from "@/store/app";
export default {
  __name: 'App',
  setup(__props) {
    const appStore = useAppStore();
    return (_ctx, _cache) => {
      const _component_el_header = _resolveComponent("el-header");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_footer = _resolveComponent("el-footer");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        local: _unref(appStore).local === 'en' ? _unref(en) : _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_container, null, {
          default: _withCtx(() => [_createVNode(_component_el_header, null, {
            default: _withCtx(() => [_createVNode(_unref(CustomerHeader))]),
            _: 1
          }), _createVNode(_component_el_main, null, {
            default: _withCtx(() => [_createVNode(_component_router_view)]),
            _: 1
          }), _createVNode(_component_el_footer, null, {
            default: _withCtx(() => [_createVNode(_unref(CustomerFooter))]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["local"]);
    };
  }
};