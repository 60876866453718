import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000
})

// 添加请求拦截器
instance.interceptors.request.use((config) => {
  return config
}, (error) => Promise.reject(error))

// 添加响应拦截器
instance.interceptors.response.use((res) => {
  return res.data
}, (error) => Promise.reject(error))

export default instance
