import { defineStore } from "pinia"
import i18n from '@/locales'
import {setLocal, getLocal} from "@/util/language"

export const useAppStore = defineStore('language', {
    state: () => ({
        local: getLocal() || 'en'
    }),
    getters: {
        is_english(state) {
            return state.local === 'en'
        }
    },
    actions: {
        SET_LOCAL(local) {
            this.local = local
            setLocal(local)
            i18n.global.locale.value = local
        }
    }
})